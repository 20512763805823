import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';
import { Image } from 'primereact/image';
import CheckIcon from "../../assets/icons/check-icon.svg";
import Icon1 from "../../assets/icons/home/icon01.svg";
import Icon3 from "../../assets/icons/programas/icon03.svg";
import Icon4 from "../../assets/icons/programas/icon04.svg";
import Icon6 from "../../assets/icons/programas/icon06.svg";
import Icon7 from "../../assets/icons/programas/icon07.svg";
import Icon8 from "../../assets/icons/programas/icon08.svg";
import Icon9 from "../../assets/icons/programas/icon09.svg";
import Icon10 from "../../assets/icons/programas/icon10.svg";
import HeaderNav from '../../components/Header';
import Footer from '../../components/Footer';
import SEO from '../../components/SEO';
import { ButtonPrimary } from '../../components/Button';
import CarouselTestimoniosTemplate from '../components/CarouselTestimoniosTemplate';
import { getBannerPrograma, getProgramas } from '../../api/client/programas';
import { useCustomState } from '../../hooks/useCustomState';
import { getCarouselTestimoniosStrengthItems } from '../../misc/getCarouselItems';
import { responsiveOptionsCarousel } from '../../utils/responsiveOptionsCarousel';
import intersectionObserver from '../../utils/intersectionObserver';
import VideoPlayer from '../../components/Modal/VideoPlayer';
import EspecialistaCertificada from '../components/EspecialistaCertificada';
import PreguntasFrecuentes from '../components/PreguntasFrecuentes';
import EntrenaApp from './components/EntrenaApp';
import Membresias from './components/Membresias';
import EvaluacionPersonalizada from '../components/EvaluacionPersonalizada';
import GlobalContext from '../../contexts/GlobalContext';
import { getPreguntasFrecuentesStrength } from '../../misc/getPreguntasFrecuentes';
import { setSubscription } from '../../redux/reducers/subscriptionSlice';

const ProgramaStrength = () => {
  const { name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const elementsRef = useRef([]);

  const { setModal } = useContext(GlobalContext);
  const [state, setState] = useCustomState({
    carouselTestimoniosItems: [],
    preguntasFrecuentes: [],
    isVideoVisible: false,
    portada_video_introduccion: null,
    video_introduccion: null,
    programaDetalles: null,
    bannerDetalles: null,
  });

  useEffect(() => {
    searchSuscripcionData();
    loadProgram();

    (async () => {
      setState({
        carouselTestimoniosItems: getCarouselTestimoniosStrengthItems(),
        preguntasFrecuentes: await getPreguntasFrecuentesStrength(),
      });
    })();

    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  const searchSuscripcionData = useCallback(async () => {
    // const _getVimeoAPI = async (id) => (await getDataVimeoApi(id))?.data;

    setState({
      portada_video_introduccion: "/assets/images/strength_fitmom.jpg", // (await _getVimeoAPI(821854458))?.video?.thumbs?.base,
      video_introduccion: {
        url: "/assets/videos/strength_fitmom.mp4",
      },
    });
  }, [setState]);

  const loadProgram = useCallback(async () => {
    try {
      const programasResponse = await getProgramas({ code: name });
      const programDetails = programasResponse?.data?.[0];

      let bannerResponse = null;
      if (programDetails?.id) {
        try {
          bannerResponse = await getBannerPrograma({ programID: programDetails.id });
        } catch (bannerError) { }
      }

      setState({
        programaDetalles: programDetails,
        bannerDetalles: bannerResponse?.data || { image: require('../../assets/images/programas/banners/banner-avanzado.jpg') },
      });
    } catch (error) {
      setState({
        bannerDetalles: { image: require('../../assets/images/programas/banners/banner-avanzado.jpg') },
      });
    }
  }, [name, setState]);

  const seleccionarSuscripcion = useCallback((plan) => {
    dispatch(setSubscription({
      data: {
        id: state.programaDetalles?.id,
        tipo_programa: "Programa de entrenamiento",
        code: state.programaDetalles?.code,
        nombre: state.programaDetalles?.name,
        plan: plan,
        precio: plan === "mensual" ? state.programaDetalles?.cost_month : state.programaDetalles?.cost_biannual,
      }
    }));
    navigate(`/carrito/strength-${plan}`);
  }, [state.programaDetalles, dispatch, navigate]);

  return (
    <>
      <SEO
        title="Programa Avanzado"
        description="Para mujeres que han completado por lo menos 6 meses del programa posparto y están listas para aumentar la intensidad."
      />
      <VideoPlayer
        responsive
        url={state.video_introduccion?.url}
        visible={state.isVideoVisible}
        onHide={() => setState({ isVideoVisible: false })}
        propsVideo={{
          controls: true,
          controlsList: "nodownload"
        }}
      />
      <div className={`bg-gradient-to-tl from-primary to-secondary bg-cover bg-no-repeat bg-center`} style={{ backgroundImage: `url(${state.bannerDetalles?.image})`, paddingTop: "calc(720 / 1920 * 100%)" }}>
        <HeaderNav />
      </div>

      <div className='bg-Magnolia pb-10'>
        <div className="xl:container mx-auto px-6">
          <div className='grid grid-cols-1 items-center gap-14'>
            <div className='col-span-1 pt-10 divide-y divide-solid divide-Crayola/50 text-center lg:text-start'>
              <div className='mb-6'>
                <h1
                  ref={(el) => elementsRef.current.push(el)}
                  className='animate__animated font-golos-medium text-md md:text-xl lg:text-2xl text-Crayola'
                  data-animation={"animate__fadeInDown"}
                >
                  (Programa Avanzado)
                </h1>
                <h1
                  ref={(el) => elementsRef.current.push(el)}
                  className='animate__animated font-golos-semibold text-xl md:text-3xl lg:text-5xl text-DeepKoamaru'
                  data-animation={"animate__fadeInUp"}
                >
                  FITMOM STRENGTH
                </h1>
                <p
                  className='text-md text-DarkJungleGreen mt-4'
                >
                  Para mujeres que han completado por lo menos 6 meses del programa posparto y están listas para aumentar la intensidad. También es ideal para mujeres con alto nivel de fitness que nunca han tenido hijos pero desean aprender sobre su core.
                </p>
                <p
                  className='text-md text-DarkJungleGreen mt-4'
                >
                  <span className='font-golos-semibold text-Crayola'>IMPORTANTE:</span> ESTE PROGRAMA NO ES APTO PARA PRINCIPIANTES O MUJERES CON DISFUNCIONES COMO DIÁSTASIS, INCONTINENCIA, PROLAPSO U OTROS PROBLEMAS DEL CORE.
                </p>
                <p
                  className='text-md text-DarkJungleGreen mt-4'
                >
                  Si tienes cualquiera de estos síntomas debes inscribirte al programa <Link to="/programas/fitmom-posparto" className='font-golos-medium underline text-Crayola'>POSPARTO</Link>
                </p>
              </div>
              <div className='pt-6'>
                <p
                  className='font-golos-semibold text-md md:text-xl lg:text-2xl text-Crayola'
                >
                  Ideal para:
                </p>
                <div className='grid md:grid-cols-2 gap-6 mt-6'>
                  <div className='flex justify-center md:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Progresar en intensidad y dificultad en la coordinación y fuerza del core.</p>
                  </div>
                  <div className='flex justify-center md:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Aumentar masa muscular.</p>
                  </div>
                  <div className='hidden md:block' />
                  <div className='flex justify-center md:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Acelerar quema de grasa.</p>
                  </div>
                  <div className='flex justify-center md:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Seguir retando al cuerpo en fuerza y resistencia.</p>
                  </div>
                  <div className='flex justify-center md:justify-start gap-3'>
                    <Image
                      src={CheckIcon}
                      imageClassName="mt-1 select-none pointer-events-none"
                      imageStyle={{ minWidth: 20, width: 20 }}
                    />
                    <p className='mb-0'>Mejorar rendimiento.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-1 flex items-center justify-center'>
              <div className='flex flex-wrap mx-auto items-center justify-center'>
                <div ref={(el) => elementsRef.current.push(el)} className='animate__animated relative flex items-center justify-center' data-animation={"animate__pulse"}>
                  <Image
                    src={state.portada_video_introduccion}
                    alt="Portada del video de introducción"
                    className='flex justify-center items-center mx-auto select-none pointer-events-none'
                    imageStyle={{ borderRadius: 25, width: "90%", maxWidth: 760 }}
                  />
                  <div className='absolute c-pointer active:scale-90 transition ease-in-out' onClick={() => setState({ isVideoVisible: true })}>
                    <Image src={require('../../assets/images/play-btn.png')} alt="icon play" imageClassName='mx-auto select-none pointer-events-none max-w-[46px] xs:max-w-[64px] sm:max-w-[80px]' imageStyle={{ width: "90%" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Membresias
        typeProgram={state.programaDetalles?.code}
        onSubscribe={seleccionarSuscripcion}
        options={{
          price_month: state.programaDetalles?.cost_month,
          price_biannual: state.programaDetalles?.cost_biannual,
        }}
      />

      <EntrenaApp />

      <div className='pt-10 pb-16 bg-white'>
        <div className="xl:container mx-auto px-6 max-w-5xl">
          <h1 ref={(el) => elementsRef.current.push(el)} className='font-golos-semibold text-Crayola text-center text-2xl mb-10 animate__animated' data-animation={"animate__fadeInDown"}>Contenidos del programa:</h1>
          <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-4'>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon1} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Fundamentos del core y suelo pélvico para reforzar las bases.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon10} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Ejercicios avanzados para mejorar fuerza y rendimiento de suelo pélvico y core.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon3} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Calendario semanal de entrenamientos avanzados de fuerza, yoga, pilates y barre.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon4} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Estiramientos para aliviar dolor de espalda baja, cuello, ciática, dolor pélvico y más.</p>
            </div>
          </div>
          <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4'>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon6} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Guía nutricional y recetario.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon7} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Documentos descargables con información complementaria.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon8} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Acceso a grupo privado de alumnas.</p>
            </div>
            <div className='bg-Magnolia rounded-md text-center items-center flex flex-col gap-8 px-5 py-8'>
              <Image src={Icon9} alt="Icono" imageClassName="select-none pointer-events-none" imageStyle={{ width: 46 }} />
              <p className='text-sm text-DarkJungleGreen mb-0'>Acceso a la aplicación móvil.</p>
            </div>
          </div>
          <EspecialistaCertificada />
        </div>
      </div>

      <EvaluacionPersonalizada />

      <div className='py-12 bg-PinkLace'>
        <div className="xl:container mx-auto px-6">
          <h1 ref={(el) => elementsRef.current.push(el)} className='font-golos-semibold text-Crayola text-center text-2xl mt-4 mb-10 animate__animated' data-animation={"animate__slideInDown"}>Preguntas frecuentes sobre el programa</h1>
          <div>
            <PreguntasFrecuentes
              data={state.preguntasFrecuentes}
            />
          </div>
          <div className='flex items-center justify-center mt-12 mb-5'>
            <div className='bg-Crayola py-3 px-6 rounded-full inline-flex'>
              <p className='text-AntiFlashWhite text-center'>¿Te quedaron dudas?, escribe a <a href='mailto:hola@jimefitmom.com' className='font-golos-semibold text-none'>hola@jimefitmom.com</a></p>
            </div>
          </div>
        </div>
      </div>

      <div className='py-12 bg-white'>
        <div className="xl:container mx-auto px-6">
          <div className='max-w-[90%] flex flex-wrap items-center justify-center sm:justify-between mx-auto gap-4 mb-6'>
            <h1 ref={(el) => elementsRef.current.push(el)} className='font-golos-semibold text-Crayola text-center text-2xl mt-8 mb-10 animate__animated' data-animation={"animate__slideInLeft"}>Testimonios de clientas felices</h1>
            <div>
              <ButtonPrimary
                text="¡Envía tu testimonio!"
                withIcon
                styleContainer={{
                  minWidth: 200
                }}
                onClick={() => setModal("testimonio", navigate)}
              />
            </div>
          </div>

          <Carousel
            value={state.carouselTestimoniosItems}
            numVisible={4}
            numScroll={4}
            responsiveOptions={responsiveOptionsCarousel}
            className="custom-carousel pagination-indicators-gray"
            circular
            showNavigators={false}
            autoplayInterval={7000}
            itemTemplate={CarouselTestimoniosTemplate}
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProgramaStrength;