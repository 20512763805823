import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const EspecialistaCertificada = () => (
  <div className='flex justify-center items-center mt-16'>
    <div className='inline-flex bg-gradient-to-t from-Magnolia to-white px-10 pb-7 rounded-2xl mx-auto'>
      <div className='flex flex-col md:flex-row items-center gap-4 mt-10'>
        <div className='text-center md:text-left md:max-w-[220px]'>
          <h1 className='font-golos-semibold text-2xl text-DeepKoamaru mb-2'>Fitness Coach Certificada Pre y Posnatal</h1>
          <p className='text-sm text-DarkJungleGreen'>Especialista en Ejercicios Correctivos Pre y Postnatales y en Diástasis y Suelo Pélvico.</p>
        </div>
        <div className='py-4'>
          <LazyLoadImage
            alt={"Logo Certificados"}
            className='w-full mx-auto select-none pointer-events-none'
            height={86}
            src={require('../../assets/images/logos_badges_Feb_2022.png')}
            style={{ maxWidth: 540 }}
            width={540}
          />
        </div>
      </div>
    </div>
  </div>
);

export default EspecialistaCertificada;