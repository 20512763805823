import { useEffect } from 'react';

/**
 * Custom hook to dynamically inject stylesheets into the document head.
 *
 * @param {string|string[]} styleSheet - A string or an array of strings containing CSS styles.
 */
export const useDynamicStyleSheet = (styleSheet) => {
  useEffect(() => {
    const styleElements = [];

    /**
     * Appends a stylesheet to the document head.
     *
     * @param {string} styleContent - The CSS styles to be added.
     */
    const appendStyleSheet = (styleContent) => {
      const styleElement = document.createElement('style');
      styleElement.innerHTML = styleContent;
      document.head.append(styleElement);
      styleElements.push(styleElement);
    };

    if (Array.isArray(styleSheet)) {
      styleSheet.forEach(sheet => appendStyleSheet(sheet));
    } else {
      appendStyleSheet(styleSheet);
    }

    return () => {
      styleElements.forEach(styleElement => styleElement.remove());
    };
  }, [styleSheet]);
};