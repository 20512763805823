import React, { useState, useEffect, useMemo } from 'react';
import ReactPlayer from 'react-player';
import { Image } from 'primereact/image';

const VimeoPlayerInline = ({ portada, urlVideo, widthOffset, containerProps, containerPortadaProps }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [playVideo, setPlayVideo] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const heightRecommended = useMemo(() => {
    return ((windowWidth * 9) / 16) - (widthOffset ?? 250);
  }, [windowWidth, widthOffset]);

  if (!portada || !urlVideo) return;

  return (
    <div className={`relative overflow-hidden w-full`} {...containerProps}>
      {playVideo ? (
        <div className='relative min-h-[140px] max-h-[464px] w-full' style={{ height: `${heightRecommended}px` }}>
          <ReactPlayer
            className="absolute top-0 left-0 w-full h-full"
            url={urlVideo}
            width="100%"
            height={`100%`}
            playing={true}
            pip={true}
            playsinline={true}
            controls={true}
            stopOnUnmount={true}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          />
        </div>
      ) : (
        <div className={`relative flex items-center justify-center overflow-hidden min-h-[180px] rounded-xl w-full ${containerPortadaProps || ''}`}>
          <Image
            src={portada}
            alt="Portada"
            className="bg-white border border-gray-100 flex justify-center items-center mx-auto h-full w-full rounded-xl"
            imageClassName='object-cover w-full h-full'
          />
          <div className="absolute c-pointer active:scale-90 transition ease-in-out z-10" onClick={() => setPlayVideo(true)}>
            <Image
              src={require('../assets/images/play-btn.png')}
              alt="icon play"
              imageClassName='mx-auto max-w-[50px] md:max-w-[68px] lg:max-w-[86px] transition ease-in-out'
              style={{ width: '90%', height: 'auto' }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(VimeoPlayerInline);