import React, { useEffect, useRef } from 'react';
import StoreApps from '../../../components/StoreApps';
import intersectionObserver from '../../../utils/intersectionObserver';

const EntrenaApp = () => {
  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative bg-Crayola">
      <div className='xl:container mx-auto px-6'>
        <div className='flex flex-wrap gap-6 pt-6 justify-center items-center'>
          <div ref={(el) => elementsRef.current.push(el)} className='animate__animated' data-animation={"animate__fadeInLeft"}>
            <img src={require('../../../assets/images/programas/banner-appimg.png')} alt="App Preview" className='max-w-xs lg:max-w-lg mx-auto select-none pointer-events-none' style={{ width: "95%" }} />
          </div>
          <div className='text-center lg:text-start'>
            <div ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInRight"} className='animate__animated max-w-[400px]'>
              <h2 className='text-xl sm:text-3xl lg:text-4xl text-AntiFlashWhite'>
                Entrena donde sea con el <span className='font-golos-bold'>app de ejercicios</span> más completa para <span className='font-golos-bold'>embarazo</span> y <span className='font-golos-bold'>posparto</span>
              </h2>
            </div>
            <div className='my-6'>
              <StoreApps />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntrenaApp;