import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import { addLocale } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ReactComponent as EyeViewIcon } from '../../../assets/icons/eye-view-icon.svg';
import { ReactComponent as EyeHideIcon } from '../../../assets/icons/eye-hide-icon.svg';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import TelefonoCodes from '../../../components/TelefonoCodes';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { getClientas, logoutClienta, updateClienta } from '../../../api/admin/clientas';
import { getHistorialActividad } from '../../../api/admin/historial_actividad';
import { getProgramas } from '../../../api/admin/programas';
import { optionsToast } from '../../../config/toast';
import { paginationComponentOptions, NoDataComponent, SortIcon } from '../../../config/table.config';
import customTableStyles from '../../../styles/customTableStyles';
import { useCustomState } from '../../../hooks/useCustomState';
import { isEmpty } from '../../../utils';
import parseErrorMessage from '../../../utils/parseErrorMessage';
import { dayjs_custom } from '../../../utils/dayjs_custom';
import getActivities, { ACTIVITY_ACTIONS, getFormatDescription } from '../../../misc/getActivities';
import { getStatusActive } from '../../../misc/getStatus';
import parseUrlSubscription from '../../../utils/parseUrlSubscription';
const prefix = "/admin/clientas";

require('dayjs/locale/es');
dayjs.locale("es");

addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar'
});

const EditarClienta = ({ isEditar }) => {
  const op = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: true,
    sending: false,
    sending_historial: false,
    userID: null,
    firstName: "",
    lastName: "",
    lastName2: "",
    phone_code: "",
    phone: "",
    city: "",
    zipCode: "",
    email: "",
    password: "",
    ip_address: "",
    lastLogin: "",
    loginDevice: "",
    logoutSession: false,
    isInternal: false,
    vigencia_inicio: null,
    vigencia_fin: null,
    isVisiblePassword: false,
    isVisibleCalendar: false,
    visibleCalendar: null,
    programa_seleccionado: null,
    listado_programas: [],
    historial_pedidos: [],
    estado_seleccionado: null,
    listado_estados: [],
    historial_actividades: [],
    filters_historial: null,
  });

  useEffect(() => {
    loadData(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (!isEmpty(state.userID)) loadDataHistorialActividades(state.userID, state.filters_historial);
    // eslint-disable-next-line
  }, [state.userID, state.filters_historial]);

  const loadData = useCallback(async (id) => {
    const clientas = await getClientas(id, null, { populate: ["program", "user_order"] });
    if (clientas?.status === true && clientas?.data?.length > 0) {
      const _clientas = clientas?.data?.[0];
      const programas = await getProgramas();

      const programas_filter = programas?.data?.find(item => item?.id === _clientas?.programID);

      const vigencia_fin = _clientas?.subscription?.dateExpirationEnd;

      setState({
        listado_estados: await getStatusActive(),
        estado_seleccionado: _clientas?.status,
        historial_pedidos: _clientas?.User?.Orders?.reverse(),
        programa_seleccionado: programas_filter,
        listado_programas: programas?.data,
        userID: _clientas?.userID,
        firstName: _clientas?.firstName || "",
        lastName: _clientas?.lastName || "",
        lastName2: _clientas?.lastName2 || "",
        phone_code: _clientas?.phone_code || "",
        phone: _clientas?.phone || "",
        city: _clientas?.city || "",
        zipCode: _clientas?.zipCode || "",
        email: _clientas?.email || "",
        vigencia_fin: isEmpty(vigencia_fin) ? null : dayjs_custom(vigencia_fin).toDate(),
        lastLogin: _clientas?.lastLogin || "",
        loginDevice: _clientas?.loginDevice || "",
        ip_address: _clientas?.ip_address || "",
        loading: false,
      });
    }
  }, [setState]);

  const loadDataHistorialActividades = useCallback(async (userID = null, filters = null) => {
    if (isEmpty(userID)) return false;

    try {
      setState({ sending_historial: true });
      const historial_actividad = await getHistorialActividad({
        userID: userID,
        action: (filters || state.filters_historial)?.action,
        createdAt: (filters || state.filters_historial)?.createdAt,
        loginDevice: (filters || state.filters_historial)?.platform,
      });

      setState({
        historial_actividades: historial_actividad?.data,
        sending_historial: false,
      });
    } catch (error) {
      setState({ sending_historial: false });
    }
  }, [state.filters_historial, setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const { plataformas, columnsTableActivity, columnsTable } = useMemo(() => ({
    plataformas: [
      {
        id: "web",
        name: "Página web",
      },
      {
        id: "app",
        name: "Aplicación móvil",
      },
    ],
    columnsTableActivity: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: 'Actividad',
        sortable: true,
        fixed: 'left',
        // minWidth: '180px',
        cell: row => getActivities(row?.action)
      },
      {
        name: 'Descripción',
        sortable: true,
        fixed: 'left',
        minWidth: '220px',
        cell: row => getFormatDescription(row?.action, row?.description, row?.User)
      },
      {
        name: 'Dirección IP',
        sortable: true,
        fixed: 'left',
        minWidth: '130px',
        cell: row => row?.ip
      },
      {
        name: 'Agente de usuario',
        sortable: false,
        fixed: 'left',
        minWidth: '180px',
        cell: row => {
          return (
            <div className='py-2'>{row?.userAgent}</div>
          )
        },
      },
      {
        name: 'Plataforma',
        sortable: true,
        fixed: 'left',
        minWidth: '120px',
        cell: row => row?.loginDevice
      },
      {
        name: 'Fecha de registro',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.createdAt && dayjs(row.createdAt).format("MM/DD/YYYY hh:mm A");
        },
        cell: row => {
          return row?.createdAt && dayjs(row.createdAt).format("MM/DD/YYYY hh:mm A");
        },
      },
    ],
    columnsTable: [
      {
        compact: true,
        grow: 0,
        width: "0px",
        style: {
          borderLeft: "1px solid #eee",
          padding: 0,
        },
      },
      {
        name: '#',
        sortable: true,
        fixed: 'left',
        minWidth: '60px',
        selector: row => row?.id,
        cell: row => row?.id
      },
      {
        name: 'Folio',
        sortable: false,
        fixed: 'left',
        minWidth: '180px',
        cell: row => row?.invoiceCode
      },
      {
        name: 'Transacciones',
        sortable: false,
        fixed: 'left',
        minWidth: '180px',
        cell: row => {
          return (
            <div className='py-2 space-y-2'>
              <div>
                <p className='font-golos-semibold'>Pago:</p>
                <span>{row.paymentTransaction || "N/A"}</span>
              </div>
              <div>
                <p className='font-golos-semibold'>Suscripción:</p>
                <span>{parseUrlSubscription(row.subscriptionTransaction, row?.paymentType)}</span>
              </div>
            </div>
          )
        }
      },
      {
        name: 'Código de cupón',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.couponCode;
        },
        cell: row => {
          return row?.couponCode;
        },
      },
      {
        name: 'Periodo de prueba',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.isTrial === true ? "Si" : "No";
        },
        cell: row => {
          return row?.isTrial === true ? "Si" : "No";
        },
      },
      {
        name: 'Suscripción manual',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.isInternal === true ? "Si" : "No";
        },
        cell: row => {
          return row?.isInternal === true ? "Si" : "No";
        },
      },
      {
        name: 'Pasarela de cobro',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.isInternal === true ? "Administrado por JimeFitMom" : row?.paymentType;
        },
        cell: row => {
          return row?.isInternal === true ? "Administrado por JimeFitMom" : row?.paymentType;
        },
      },
      {
        name: 'Programa',
        sortable: true,
        fixed: 'left',
        minWidth: '120px',
        selector: row => {
          return (`${row.Program?.name}${row?.typePlan ? " - " + row?.typePlan : ""}`);
        },
        cell: row => {
          return (`${row.Program?.name}${row?.typePlan ? " - " + row?.typePlan : ""}`);
        },
      },
      {
        name: 'Precio',
        sortable: true,
        fixed: 'left',
        minWidth: '120px',
        selector: row => {
          return parseFloat(row?.initialPayment || 0).toFixed(2);
        },
        cell: row => {
          return `$${parseFloat(row?.initialPayment || 0).toFixed(2)}`;
        },
      },
      {
        name: 'Estado',
        sortable: true,
        fixed: 'left',
        minWidth: '130px',
        selector: row => {
          if (row?.status) {
            if (["trialing", "paid"].includes(row.status)) return "Activa";
            if (row.status === "hold") return "En espera";
            if (row.status === "rejected") return "Rechazada";
            if (["cancelled", "expired", "paused"].includes(row?.status) && row.status_to_expire) return row.status_to_expire;
            if (row.status === "expired") return "Expirada";
            if (row.status === "cancelled") return "Cancelada";
          } else {
            return "";
          }
        },
        cell: row => {
          if (row?.status) {
            if (["trialing", "paid"].includes(row.status)) return <span className='bg-green-600 text-white rounded-lg py-2 px-4'>Activa</span>;
            if (row.status === "hold") return <span className='bg-amber-500 text-white rounded-lg py-2 px-4'>En espera</span>;
            if (row.status === "rejected") return <span className='bg-red-500 text-white rounded-lg py-2 px-4'>Rechazada</span>;
            if (["cancelled", "expired", "paused"].includes(row?.status) && row.status_to_expire) return <span className={`${["Expirada", "Cancelada"].includes(row.status_to_expire) ? "bg-red-500" : "bg-green-500"} text-white rounded-lg py-2 px-4`}>{row.status_to_expire}</span>;
            if (row.status === "expired") return <span className='bg-red-500 text-white rounded-lg py-2 px-4'>Expirada</span>;
            if (row.status === "cancelled") return <span className='bg-red-600 text-white rounded-lg py-2 px-4'>Cancelada</span>;
          }
          return "";
        },
      },
      {
        name: 'Fecha de adquisición',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.subscriptionStartDate && dayjs.utc(row.subscriptionStartDate).format("MM/DD/YYYY");
        },
        cell: row => {
          return row?.subscriptionStartDate && dayjs.utc(row.subscriptionStartDate).format("MM/DD/YYYY");
        },
      },
      {
        name: 'Fecha de vigencia (Es interna)',
        sortable: true,
        fixed: 'left',
        minWidth: '230px',
        selector: row => {
          return row?.dateExpirationEnd && dayjs(row.dateExpirationEnd).format("MM/DD/YYYY");
        },
        cell: row => {
          return row?.dateExpirationEnd && dayjs(row.dateExpirationEnd).format("MM/DD/YYYY");
        },
      },
      {
        name: 'Fecha de renovación',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.nextBillingDate && dayjs.utc(row.nextBillingDate).format("MM/DD/YYYY");
        },
        cell: row => {
          return row?.nextBillingDate && dayjs.utc(row.nextBillingDate).format("MM/DD/YYYY");
        },
      },
      {
        name: 'Fecha de cancelación',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.cancelledAt && dayjs(row.cancelledAt).format("MM/DD/YYYY");
        },
        cell: row => {
          return row?.cancelledAt && dayjs(row.cancelledAt).format("MM/DD/YYYY");
        },
      },
      {
        name: 'Fecha de expiración',
        sortable: true,
        fixed: 'left',
        minWidth: '180px',
        selector: row => {
          return row?.subscriptionCancelDate && dayjs.utc(row.subscriptionCancelDate).format("MM/DD/YYYY");
        },
        cell: row => {
          return row?.subscriptionCancelDate && dayjs.utc(row.subscriptionCancelDate).format("MM/DD/YYYY");
        },
      },
    ],
  }), []);

  const onUpdate = useCallback(async () => {
    if (isEditar !== true) return;

    if (state.isInternal === true && isEmpty(state.programa_seleccionado)) {
      return message(null, 'Selecciona un programa para continuar.');
    }

    try {
      setState({ sending: true });

      if (state.logoutSession === true) {
        try {
          await logoutClienta(state.userID);
        } catch (error) { }
      }

      const response = await updateClienta(id, {
        programID: state.isInternal === true ? state.programa_seleccionado?.id : null,
        firstName: state.firstName,
        lastName: state.lastName,
        lastName2: state.lastName2,
        phone_code: state.phone_code?.dial_code,
        phone: state.phone,
        email: state.email,
        password: !isEmpty(state.password) ? state.password : null,
        isInternal: state.isInternal,
        status: state.estado_seleccionado,
        // dateExpirationStart: state.isInternal === true ? dayjs(state.vigencia_inicio).toISOString() : null,
        dateExpirationEnd: state.isInternal === true ? dayjs(state.vigencia_fin).toISOString() : null,
      });

      setState({ sending: false });

      if (response?.status === true) {
        message('Clienta actualizada', '¡La clienta se ha actualizado exitosamente!');
        loadData(id);
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar la clienta. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar la clienta. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [id, isEditar, state, setState, loadData]);

  return (
    <>
      <SEO
        title={isEditar ? "Editar clienta" : "Ver clienta"}
      />
      <Dialog
        visible={state.isVisibleCalendar}
        onHide={() => setState({ isVisibleCalendar: false })}
        style={{ width: "60%", margin: "auto" }}
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      >
        <Calendar
          value={state.visibleCalendar === "vigencia_inicio" ? state.vigencia_inicio : state.vigencia_fin}
          onChange={(e) => setState({ [state.visibleCalendar]: e.value, isVisibleCalendar: false })}
          showOnFocus={false}
          dateFormat="M dd, yy"
          readOnlyInput
          locale="es"
          visible={true}
          inline={true}
          style={{ position: "relative", zIndex: 9999, width: "100%" }}
        />
      </Dialog>
      <OverlayPanel
        ref={op}
        showCloseIcon={false}
        className={"shadow-sm shadow-[#ddd] max-w-[720px] w-full mx-4"}
      >
        <div className='divide-y divide-[#ddd] space-y-4 text-center sm:text-left'>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Actividad</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {Object.values(ACTIVITY_ACTIONS)?.map(item => (
                <div
                  key={`action-key-${item}`}
                  className={`${state.filters_historial?.action?.includes(item) ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    setState({ action: !state.filters_historial?.action?.includes(item) ? item : null }, 'filters_historial');
                  }}
                >
                  <p className='text-sm'>{getActivities(item)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Fecha de registro</p>
            <div className='flex flex-1 flex-col sm:flex-row items-center gap-2'>
              <input
                type="date"
                className="rounded-md border-gris-F7F7F7 text-sm !outline-none !ring-0 !ring-offset-0 transition-all"
                onChange={(event) => setState({ createdAt: event.target.value && dayjs(event.target.value)?.toISOString() }, 'filters_historial')}
                value={(!isEmpty(state.filters_historial?.createdAt)) ? dayjs(state.filters_historial?.createdAt).format("YYYY-MM-DD") : ""}
              />
            </div>
          </div>
          <div className='flex flex-col sm:flex-row items-center gap-4 pt-4'>
            <p className='sm:min-w-[140px]'>Plataformas</p>
            <div className='flex flex-1 flex-wrap items-center gap-2'>
              {plataformas.map(item => (
                <div
                  key={`plataformas-${item?.id}`}
                  className={`${state.filters_historial?.platform?.includes(item?.id) ? "bg-Crayola text-white" : "bg-gris-F2F2F2 text-neutral-600"} hover:bg-Crayola hover:text-white rounded-lg px-6 py-2 c-pointer transition-all`}
                  onClick={() => {
                    setState({ platform: !state.filters_historial?.platform?.includes(item?.id) ? item?.id : null }, 'filters_historial');
                  }}
                >
                  <p className='text-sm'>{item?.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </OverlayPanel>
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>{isEditar ? "Editar clienta" : "Ver clienta"}</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col gap-4 mt-10 mx-6 lg:mx-8'>
              <div className='flex flex-col bg-white rounded-lg px-8 py-10'>
                <div className='flex flex-col sm:flex-row gap-4'>
                  <div className='flex flex-col gap-4 flex-1'>
                    <input
                      type="text"
                      placeholder='Nombre'
                      className="cursor-not-allowed bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.firstName}
                      onChange={(event) => setState({ firstName: event.target.value })}
                      readOnly
                    />
                    <input
                      type="text"
                      placeholder='Apellido paterno'
                      className="cursor-not-allowed bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.lastName}
                      onChange={(event) => setState({ lastName: event.target.value })}
                      readOnly
                    />
                    <input
                      type="text"
                      placeholder='Apellido materno'
                      className="cursor-not-allowed bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.lastName2}
                      onChange={(event) => setState({ lastName2: event.target.value })}
                      readOnly
                    />
                    <TelefonoCodes
                      selectedCode={state?.phone_code}
                      onChangeCode={(event) => setState({ phone_code: event.value })}
                      value={state.phone}
                      onChange={(event) => setState({ phone: event.target.value })}
                    />
                    <input
                      type="text"
                      placeholder='Ciudad'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.city}
                      onChange={(event) => setState({ city: event.target.value })}
                      disabled={!isEditar}
                    />
                    <input
                      type="number"
                      placeholder='Código postal'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      maxLength={5}
                      value={state?.zipCode}
                      onChange={(event) => setState({ zipCode: event.target.value })}
                      disabled={!isEditar}
                    />
                    <input
                      type="email"
                      placeholder='Correo electrónico'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.email}
                      onChange={(event) => setState({ email: event.target.value })}
                      disabled={!isEditar}
                    />
                    <div className='flex items-center relative'>
                      <input
                        autoComplete='false'
                        type={state.isVisiblePassword ? "text" : "password"}
                        placeholder='Contraseña'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.password}
                        onChange={(event) => setState({ password: event.target.value })}
                        disabled={!isEditar}
                      />
                      {isEditar && (
                        <span className='absolute right-0 p-3 c-pointer active:scale-90 transition-all select-none' onClick={() => setState({ isVisiblePassword: !state.isVisiblePassword })}>
                          {!state.isVisiblePassword ? <EyeViewIcon height={20} width={20} /> : <EyeHideIcon height={20} width={20} />}
                        </span>
                      )}
                    </div>
                    <Dropdown
                      value={state?.estado_seleccionado}
                      onChange={(event) => setState({ estado_seleccionado: event.target.value })}
                      options={state.listado_estados}
                      optionLabel="name"
                      placeholder="Selecciona Estado"
                      emptyMessage="No se encontraron resultados"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      disabled={!isEditar}
                    />
                    <div className='flex items-center justify-center gap-4'>
                      {isEditar && (
                        <label className='inline-flex items-center gap-3 c-pointer mt-2'>
                          <input
                            checked={state.logoutSession}
                            type="checkbox"
                            className="bg-Magnolia !border-0 !outline-none !shadow-none !ring-0 !ring-offset-0 p-3 !rounded-md text-Crayola transition-all"
                            onChange={(event) => setState({ logoutSession: event.target.checked })}
                          />
                          <span className="text-sm">Cerrar sesión a clienta</span>
                        </label>
                      )}
                      <label className='inline-flex items-center gap-3 c-pointer mt-2'>
                        <input
                          checked={true}
                          type="checkbox"
                          className="bg-Magnolia !border-0 !outline-none !shadow-none !ring-0 !ring-offset-0 p-3 !rounded-md text-Crayola transition-all"
                          onChange={(event) => setState({ logoutSession: event.target.checked })}
                          disabled={true}
                        />
                        <span className="text-sm">Aceptó los términos y condiciones</span>
                      </label>
                    </div>
                  </div>
                  <div className='flex flex-col gap-4 flex-1'>
                    <label className='inline-flex items-center gap-3 c-pointer'>
                      <input
                        checked={state.isInternal}
                        type="checkbox"
                        className="bg-Magnolia !border-0 !outline-none !shadow-none !ring-0 !ring-offset-0 p-3 !rounded-md text-Crayola transition-all"
                        onChange={(event) => setState({ isInternal: event.target.checked })}
                        disabled={!isEditar}
                      />
                      <span className="flex-1 text-sm">Administrar suscripción manualmente</span>
                    </label>
                    <Dropdown
                      value={state?.programa_seleccionado}
                      onChange={(event) => setState({ programa_seleccionado: event.target.value })}
                      options={state.listado_programas}
                      optionLabel="name"
                      placeholder="Selecciona programa"
                      emptyMessage="No se encontraron resultados"
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      disabled={!state.isInternal}
                    />
                    {/* <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                      <p className='truncate'>{(state.vigencia_inicio && dayjs(state.vigencia_inicio).format("YYYY-MM-DD")) || "Vigencia inicio"}</p>
                      {(isEditar && state.isInternal) && (<button className='underline' onClick={() => setState({ isVisibleCalendar: true, visibleCalendar: "vigencia_inicio" })}>Ver calendario</button>)}
                    </div> */}
                    <div>
                      <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                        <p className='truncate'>{(state.vigencia_fin && dayjs(state.vigencia_fin).format("MM/DD/YYYY")) || "Vigencia"}</p>
                        {(isEditar && state.isInternal) && (<button className='underline' onClick={() => setState({ isVisibleCalendar: true, visibleCalendar: "vigencia_fin" })}>Ver calendario</button>)}
                      </div>
                      <p className='text-muted text-gray-400 mt-1.5 pl-2'>Hasta cuando se mantiene activo</p>
                    </div>
                    <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                      <p className=''><strong>Dirección IP:</strong> {state.ip_address || "No registrada"}</p>
                    </div>
                    <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                      <p className=''><strong>Último ingreso:</strong> {(state.lastLogin && dayjs(state.lastLogin).format("MM/DD/YYYY") + (state.loginDevice && " - " + state.loginDevice))}</p>
                    </div>
                  </div>
                </div>
                <div className='flex justify-center items-center gap-3 mt-10'>
                  <ButtonCustom
                    text={isEditar ? "CANCELAR" : "REGRESAR"}
                    classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                    onClick={() => navigate(prefix)}
                    withIconBack={!isEditar}
                  />
                  {isEditar && (
                    <ButtonCustom
                      text="ACTUALIZAR"
                      classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                      onClick={onUpdate}
                      loading={state.sending}
                    />
                  )}
                </div>
              </div>
              <div className='flex flex-col bg-white rounded-lg px-8 py-6'>
                <h1 className='text-Crayola font-golos-medium text-xl'>Historial de pedidos</h1>
                <div className='container_table mt-6' style={{ borderRadius: 10 }}>
                  <DataTable
                    columns={columnsTable}
                    data={state.historial_pedidos}
                    striped={false}
                    progressPending={state.sending}
                    pagination={true}
                    responsive={true}
                    paginationComponentOptions={paginationComponentOptions}
                    subHeader={false}
                    customStyles={customTableStyles}
                    sortIcon={<SortIcon />}
                    noDataComponent={<NoDataComponent />}
                  />
                </div>
              </div>
              <div className='flex flex-col bg-white rounded-lg px-8 py-6'>
                <div className='flex-1 inline-flex items-center justify-center flex-wrap xs:flex-nowrap gap-3'>
                  <h1 className='flex-1 text-Crayola font-golos-medium text-xl'>Historial de actividades</h1>
                  <Button
                    type="button"
                    className='flex items-center font-golos-semibold text-sm border-transparent p-button bg-Crayola hover:!bg-DeepKoamaru hover:!border-DeepKoamaru text-AntiFlashWhite !py-2 !px-4 overflow-visible'
                    icon={<i className="pi pi-filter" style={{ fontSize: '0.84rem', marginRight: 7 }}></i>}
                    label="Filtros"
                    onClick={(e) => op.current.toggle(e)}
                  />
                </div>
                <div className='container_table mt-6' style={{ borderRadius: 10 }}>
                  <DataTable
                    columns={columnsTableActivity}
                    data={state.historial_actividades}
                    striped={false}
                    progressPending={state.sending_historial}
                    pagination={true}
                    responsive={true}
                    paginationComponentOptions={paginationComponentOptions}
                    subHeader={false}
                    customStyles={customTableStyles}
                    sortIcon={<SortIcon />}
                    noDataComponent={<NoDataComponent />}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default EditarClienta;