import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Image } from "primereact/image";
import LogoFacebook from "../assets/icons/logo-facebook.svg";
import LogoTikTok from "../assets/icons/logo-tiktok.svg";
import LogoInstagram from "../assets/icons/logo-instagram.svg";
import LogoYoutube from "../assets/icons/logo-youtube.svg";
import { downloadApps, socialMedia } from "../config/app";
import GlobalContext from "../contexts/GlobalContext";

const Footer = () => {
  const { setModal } = useContext(GlobalContext);

  return (
    <footer className="bg-Orchid">
      <div className="bg-Orchid xl:container mx-auto px-6 py-8 items-center text-center lg:text-start animate__animated animate__bounce">
        <div className="grid sm:grid-cols-12 gap-6">
          <div className="col-span-1 sm:col-span-6 md:col-span-4 lg:col-span-3 flex flex-col space-y-2">
            <div className="mx-auto">
              <Link to="/">
                <Image
                  src={require("../assets/images/logo.png")}
                  alt="Logo"
                  imageClassName="select-none pointer-events-none mb-2"
                  imageStyle={{ maxWidth: 140 }}
                  height={46}
                  width={140}
                />
              </Link>
            </div>
            <div className="mx-auto">
              <Link to="/terminos-condiciones" className="hover:text-sky-500 transition">Términos y Condiciones</Link>
            </div>
            <div className="mx-auto">
              <Link to="/politica-privacidad" className="hover:text-sky-500 transition">Política de privacidad</Link>
            </div>
            <div className="mx-auto">
              <Link to="/politica-cookies" className="hover:text-sky-500 transition">Política de cookies</Link>
            </div>
          </div>
          <div className="col-span-1 sm:col-span-6 md:col-span-4 lg:col-span-3 grid grid-cols-2 gap-6">
            <div className="flex flex-col space-y-2">
              <div className="mx-auto sm:mx-none">
                <p className="font-golos-bold text-DeepKoamaru">Programas:</p>
              </div>
              <div className="mx-auto sm:mx-none">
                <Link to="/programas/fitmom-embarazo" className="hover:text-sky-500 transition">
                  Fitmom Embarazo
                </Link>
              </div>
              <div className="mx-auto sm:mx-none">
                <Link to="/programas/fitmom-posparto" className="hover:text-sky-500 transition">
                  Fitmom Posparto
                </Link>
              </div>
              <div className="mx-auto sm:mx-none">
                <Link to="/programas/fitmom-strength" className="hover:text-sky-500 transition">
                  Fitmom Strength
                </Link>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <div className="mx-auto sm:mx-none">
                <Link to="/sobre-jime" className="hover:text-sky-500 transition">
                  Sobre Jime
                </Link>
              </div>
              <div className="mx-auto sm:mx-none">
                <a href="https://calendly.com/jimenacantu" className="hover:text-sky-500 transition" rel="noopener noreferrer" target="_blank">
                  Consultas
                </a>
              </div>
              <div className="mx-auto sm:mx-none">
                <Link to="/blog" className="hover:text-sky-500 transition">
                  Blog
                </Link>
              </div>
              <div className="mx-auto sm:mx-none">
                <div
                  className={"hover:text-sky-500 transition c-pointer select-none"}
                  onClick={() => setModal("contacto")}
                >
                  Contacto
                </div>
              </div>
              <div className="mx-auto sm:mx-none">
                <a href="https://jimefitmom.vip/fitmom-connect" className="hover:text-sky-500 transition" rel="noopener noreferrer" target="_blank">
                  Newsletter
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-1 sm:col-span-6 md:col-span-4 lg:col-span-3 flex flex-col space-y-2">
            <div className="mx-auto sm:mx-none">
              <p className="font-golos-bold text-DeepKoamaru">Sígueme en mis redes sociales:</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mx-auto lg:mx-none">
                <Link to={socialMedia.facebook} className="flex space-x-2 items-center" target="_blank">
                  <Image
                    src={LogoFacebook}
                    alt="Icono de Facebook"
                    imageStyle={{ height: 14, width: 14 }}
                    height={14}
                    width={14}
                  />
                  <span className="font-golos-medium text-AntiFlashWhite underline">Facebook</span>
                </Link>
              </div>
              <div className="mx-auto lg:mx-none">
                <Link to={socialMedia.tiktok} className="flex space-x-2 items-center" target="_blank">
                  <Image
                    src={LogoTikTok}
                    alt="Icono de Tik Tok"
                    imageStyle={{ height: 14, width: 14 }}
                    height={14}
                    width={14}
                  />
                  <span className="font-golos-medium text-AntiFlashWhite underline">Tik Tok</span>
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="mx-auto lg:mx-none">
                <Link to={socialMedia.instagram} className="flex space-x-2 items-center" target="_blank">
                  <Image
                    src={LogoInstagram}
                    alt="Icono de Instagram"
                    imageStyle={{ height: 14, width: 14 }}
                    height={14}
                    width={14}
                  />
                  <span className="font-golos-medium text-AntiFlashWhite underline">Instagram</span>
                </Link>
              </div>
              <div className="mx-auto lg:mx-none">
                <Link to={socialMedia.youtube} className="flex space-x-2 items-center" target="_blank">
                  <Image
                    src={LogoYoutube}
                    alt="Icono de Youtube"
                    imageStyle={{ height: 14, width: 14 }}
                    height={14}
                    width={14}
                  />
                  <span className="font-golos-medium text-AntiFlashWhite underline">YouTube</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-1 sm:col-span-6 md:col-span-12 lg:col-span-3 flex flex-col space-y-2 items-center lg:items-start">
            <div className="mx-auto lg:mx-none">
              <p className="text-DeepKoamaru mb-2">Entrena donde sea con la <span className="font-golos-bold">app Jimefitmom</span></p>
            </div>
            <div className="flex flex-wrap items-center gap-2">
              <div className="mx-auto lg:mx-none">
                <Link to={downloadApps.app_store} target="_blank">
                  <Image
                    src={require("../assets/images/Appstore.png")}
                    alt="Icono de App Store"
                    imageStyle={{ height: 38, maxWidth: 120 }}
                    height={38}
                    width={109}
                  />
                </Link>
              </div>
              <div className="mx-auto lg:mx-none">
                <Link to={downloadApps.play_store} target="_blank">
                  <Image
                    src={require("../assets/images/Googleplay.png")}
                    alt="Icono de Play Store"
                    imageStyle={{ height: 39, maxWidth: 120 }}
                    height={39}
                    width={112}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 my-5 text-center">
        &#174; {new Date().getFullYear()} JIMEFITMOM JIMENA CANTÚ. Todos los derechos reservados.
      </div>
    </footer>
  );
}

export default Footer;