import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EyeHideIcon } from "../../assets/icons/eye-hide-icon.svg";
import { ReactComponent as EyeViewIcon } from "../../assets/icons/eye-view-icon.svg";
import HeaderNav from '../../components/Header';
import SEO from '../../components/SEO';
import Grid from '../../components/Grid';
import { useCustomState } from '../../hooks/useCustomState';
import Loading from '../../components/Loading';
import { ButtonPrimary } from '../../components/Button';
import { getPerfil, updatePerfil } from '../../api/client/perfil';
import { setUser } from '../../redux/reducers/authSlice';
import parseErrorMessage from '../../utils/parseErrorMessage';
import { optionsToast } from '../../config/toast';
import TelefonoCodes from '../../components/TelefonoCodes';

const Configuracion = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.app.auth);
  const [state, setState] = useCustomState({
    loading: true,
    saving: false,
    change_password: false,
    form: {
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      correo: "",
      contrasenia_actual: "",
      contrasenia_nueva: "",
      visiblePasswordActual: false,
      visiblePasswordNueva: false,
      phone_code: "",
      telefono: "",
      ciudad: "",
      codigo_postal: "",
    },
  });

  useEffect(() => {
    setState({
      loading: false,
      form: {
        nombre: user.firstName || "",
        apellido_paterno: user.lastName || "",
        apellido_materno: user.lastName2 || "",
        correo: user.email || "",
        phone_code: user.phone_code || "",
        telefono: user.phone || "",
        ciudad: user.city || "",
        codigo_postal: user.zipCode || "",
      },
    });

    // eslint-disable-next-line
  }, [user]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const onSave = useCallback(async (event) => {
    event?.preventDefault();

    try {
      setState({ saving: true });

      const response = await updatePerfil(user?.id, {
        'firstName': state?.form?.nombre,
        'lastName': state?.form?.apellido_paterno,
        'lastName2': state?.form?.apellido_materno,
        'city': state?.form?.ciudad,
        'zipCode': state?.form?.codigo_postal,
        'email': state?.form?.correo,
        'phone_code': state?.form?.phone_code?.dial_code,
        'phone': state?.form?.telefono,
        'password': state?.form?.contrasenia_actual,
        'password_new': state?.form?.contrasenia_nueva,
      });

      setState({ saving: false, change_password: false });

      if (response?.status === true) {
        getPerfil({ id: user?.id, email: user?.email }).then(response => (response?.status === true && response?.data) && dispatch(setUser(response?.data)));
        message('Cuenta actualizada', '¡La cuenta se ha actualizado exitosamente!');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al actualizar la cuenta. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ saving: false, change_password: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al actualizar la cuenta. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [user?.id, user?.email, state, setState, dispatch]);

  return (
    <>
      <SEO
        title="Mi Cuenta"
        description="Fitness Coach Certificada Pre y Posnatal y Especialista en Ejercicios Correctivos, en Diástasis y en Core."
      />
      <HeaderNav
        theme="white"
      />

      <div className='bg-Magnolia'>
        <div className="xl:container mx-auto px-6 py-20">
          <Grid>
            <div className='flex flex-col gap-4'>
              <div className='flex flex-col gap-8 bg-white p-8 rounded-xl'>
                <div className='flex flex-col text-center md:text-left'>
                  <p className='font-golos-semibold text-Crayola text-2xl'>Mi Cuenta</p>
                  <p>A continuación se muestra tu información personal.</p>
                </div>
                {state.loading ? (
                  <Loading />
                ) : (
                  <div className='flex flex-col sm:flex-row gap-6 md:gap-16 mb-4'>
                    <form autoComplete="off" className='flex flex-col flex-1 space-y-4 md:mb-20' onSubmit={onSave}>
                      <input
                        type="text"
                        required
                        placeholder='Nombre'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.form?.nombre}
                        onChange={(event) => setState({ nombre: event.target.value }, 'form')}
                      />
                      <input
                        type="text"
                        required
                        placeholder='Apellido paterno'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.form?.apellido_paterno}
                        onChange={(event) => setState({ apellido_paterno: event.target.value }, 'form')}
                      />
                      <input
                        type="text"
                        required
                        placeholder='Apellido materno'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.form?.apellido_materno}
                        onChange={(event) => setState({ apellido_materno: event.target.value }, 'form')}
                      />
                      <input
                        autoComplete='off'
                        role="presentation"
                        type="email"
                        required
                        placeholder='Correo electrónico'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.form?.correo}
                        onChange={(event) => setState({ correo: event.target.value }, 'form')}
                      />
                      {state.change_password === true && (
                        <div className='relative flex items-center'>
                          <input
                            autoComplete='off'
                            role="presentation"
                            type={state.visiblePasswordActual ? "text" : "password"}
                            required
                            placeholder='Contraseña actual'
                            className={`bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 rounded-md text-sm text-gray-900 w-full transition-all`}
                            value={state?.form?.contrasenia_actual}
                            onChange={(event) => setState({ contrasenia_actual: event.target.value }, 'form')}
                          />
                          <span className='absolute right-0 p-3 c-pointer z-10' onClick={() => setState({ visiblePasswordActual: !state.visiblePasswordActual })}>
                            {state.visiblePasswordActual ? <EyeHideIcon height={16} width={16} /> : <EyeViewIcon height={16} width={16} />}
                          </span>
                        </div>
                      )}
                      <div className='relative flex items-center'>
                        <div className="flex items-center bg-Magnolia relative rounded-md text-sm w-full transition-all">
                          <input
                            autoComplete='off'
                            role="presentation"
                            type={state.visiblePasswordNueva ? "text" : "password"}
                            required
                            placeholder={state.change_password ? 'Contraseña nueva' : '* * * * * * * * * * * *'}
                            className={`w-full min-w-[70px] flex-auto xs:flex-1 bg-transparent border-0 outline-none focus:border-0 focus:outline-none focus:ring-0 placeholder-gray-500 text-gray-900 px-3 py-3 rounded-md text-sm ${state.change_password === false ? "pointer-events-none" : ""}`}
                            value={state?.form?.contrasenia_nueva}
                            disabled={state.change_password ? false : true}
                            onChange={(event) => setState({ contrasenia_nueva: event.target.value }, 'form')}
                          />
                          {state.change_password === true && (
                            <span className='absolute right-0 p-3 c-pointer z-10' onClick={() => setState({ visiblePasswordNueva: !state.visiblePasswordNueva })}>
                              {state.visiblePasswordNueva ? <EyeHideIcon height={16} width={16} /> : <EyeViewIcon height={16} width={16} />}
                            </span>
                          )}
                          {state.change_password === false && <p className='c-pointer p-3 text-Crayola underline' onClick={() => setState({ change_password: true })}>Cambiar contraseña</p>}
                        </div>
                      </div>
                      <TelefonoCodes
                        selectedCode={state.form?.phone_code}
                        onChangeCode={(event) => setState({ phone_code: event.value }, "form")}
                        value={state.form.telefono}
                        onChange={(event) => setState({ telefono: event.target.value }, "form")}
                      />
                      <input
                        type="text"
                        required
                        placeholder='Ciudad'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.form?.ciudad}
                        onChange={(event) => setState({ ciudad: event.target.value }, 'form')}
                      />
                      <input
                        type="text"
                        required
                        placeholder='Código postal'
                        className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                        value={state?.form?.codigo_postal}
                        onChange={(event) => setState({ codigo_postal: event.target.value }, 'form')}
                      />
                    </form>
                    <div className='flex items-end justify-center md:justify-end'>
                      <ButtonPrimary
                        text="Guardar cambios"
                        withIcon={false}
                        loading={state.saving}
                        loadingText="Guardando..."
                        onClick={onSave}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Configuracion;