export const getPreguntasFrecuentesEmbarazo = async () => {
  return [
    {
      title: "¿A partir de qué semana de embarazo puedo iniciar el programa de ejercicio?",
      description: "Mientras tu embarazo no tenga riesgo y tengas aprobación de tu médico, puedes iniciarlo desde el primer mes.",
    },
    {
      title: "¿El programa tiene ejercicios por trimestre de embarazo?",
      description: "No. Ya que cada mujer es diferente, no existe una regla de ejercicios por trimestres y no se puede estandarizar. Habrá mujeres que puedan seguir con la misma intensidad en todo el embarazo y habrá quienes no. En todos mis entrenamientos doy alternativas de ejercicios para cuando las necesites y también te doy una guía de cómo puedes modificar la frecuencia e intensidad del ejercicio de acuerdo a tu nivel. Al final, lo más importante es ir escuchando a tu cuerpo y hacer modificaciones conforme sea necesario.",
    },
    {
      title: "¿Puedo hacer el programa de embarazo si no hacía ejercicio antes?",
      description: "Si. El ejercicio tiene muchos beneficios durante el embarazo. Empieza siguiendo las recomendaciones de nivel principiante y conforme te vayas adaptando y sintiendo puedes ir aumentando la frecuencia e intensidad de entrenamientos.",
    },
    {
      title: "¿Puedo iniciar el programa de ejercicio de embarazo si ya estoy en el tercer trimestre?",
      description: "Si. Siempre será mejor algo que nada. En este caso mi recomendación es enfocarte únicamente en los ejercicios del core y los de preparación al parto y combinar con 20 minutos de caminata al día.",
    },
    {
      title: "¿Cuánto tiempo duran los entrenamientos y cuántos son por semana?",
      description: "Los videos de entrenamiento del core duran 10-15 minutos en promedio y se practican diario. Los entrenamientos de fuerza duran 40 min en promedio y se realizan de 2 a 5 veces por semana dependiendo de tu nivel.",
    },
    {
      title: "¿Los ejercicios son grabados o en vivo?",
      description: "Son entrenamientos pregrabados.",
    },
    {
      title: "¿El calendario semanal de ejercicios se actualiza cada semana?",
      description: "Si se actualiza automáticamente con la sugerencia de entrenamientos para cada día.",
    },
    {
      title: "¿Qué equipo necesito para realizar el programa de ejercicios para embarazo?",
      description: "Un tapete de yoga, un par de mancuernas ligeras (2-3kg), ligas de resistencia y una pelota de estabilidad.",
    },
    {
      title: "¿Qué medida de pelota debo comprar?",
      description: `La medida de pelota depende de tu estatura. La recomendación es la siguiente:
      1.50m-1.65m    55cm
      1.66m-1.80m    65cm
      1.80m          75cm`,
    },
    {
      title: "¿Se puede adquirir el programa de ejercicios para embarazadas desde cualquier país?",
      description: "Si. El precio es en dólares pero se hace la conversión automáticamente a tu moneda local.",
    },
    {
      title: "¿Cómo obtengo los 7 días gratis?",
      description: "Al suscribirte a cualquier plan ya sea mensual o semestral obtienes automáticamente los primeros 7 días gratis. Es necesario poner tus datos de pago, pero el cobro no se realizará hasta después del periodo de prueba. Los 7 días de prueba gratis solo se obtienen la primera vez que te inscribes al programa, en caso de un reingreso el cobro se hará en automático ese mismo dia.",
    },
    {
      title: "¿La suscripción se renueva automáticamente?",
      description: "Si. Si es suscripción mensual se renueva cada mes o si es semestral cada 6 meses mientras no canceles. Es responsabilidad de cada usuario cancelar la suscripción cuando ya no desea o no puede usarla para evitar futuros cobros. En caso de olvidar cancelar no hay reembolsos.",
    },
    {
      title: "¿Qué velocidad de internet necesito para ver el contenido?",
      description: "Para evitar tener problemas de conexión asegúrate de tener 8 MBPS en tu velocidad de internet.",
    },
  ]
}

export const getPreguntasFrecuentesPosparto = async () => {
  return [
    {
      title: "¿Cuánto tiempo después de tener a mi bebé puedo comenzar con ejercicios?",
      description: "La etapa 1 de mi programa está especialmente diseñada para iniciarse desde los primeros días después del parto o la cesárea. Estos ejercicios son ejercicios gentiles de respiración que te ayudarán a acelerar tu recuperación y a rehabilitar adecuadamente tu suelo pélvico y core antes de regresar a otros ejercicios. Y si ya pasaste la cuarentena y no hiciste estos ejercicios, como quiera es importante que los hagas.",
    },
    {
      title: "¿Puedo hacer el programa si ya pasó mucho tiempo de haber tenido a mi bebé?",
      description: "Si. Así tengas meses o años posparto, nunca es tarde para mejorar.",
    },
    {
      title: "¿El programa de ejercicios posparto es apto para cesárea?",
      description: "Si. El programa es el mismo para parto o cesárea. También hay videos extra específicos para cesárea.",
    },
    {
      title: "¿Este programa de ejercicios posparto me ayudará a corregir diástasis?",
      description: "Si. Todos los ejercicios del core de la etapa 1 son ideales para eso. Además, hay ejercicios extra con enfoque en diástasis y un video que te guía en cómo hacerte el test para saber si tienes diástasis.",
    },
    {
      title: "¿Este programa de ejercicios posparto incluye hipopresivos?",
      description: "Los hipopresivos no es una técnica para todas, por lo que es importante que no trates de hacerlos si no tienes una guía e indicación de un especialista. La técnica que aprenderás en mi programa se llama Core Breathing Belly Pump. A diferencia de los hipopresivos, esta técnica no emplea apnea ni vacíos abdominales, sino que trabaja con la respiración en todo momento y en la coordinación del diafragma, suelo pélvico y transverso abdominal. Además, es una técnica que se puede realizar tanto en embarazo como en posparto inmediato y que te ayudará a corregir problemas como diástasis, incontinencia, entre otras disfunciones.",
    },
    {
      title: "¿El programa de ejercicio de postparto también incluye ejercicios para trabajar otros grupos musculares además del core?",
      description: "Si. En la etapa 2 del programa empezarás a combinar ejercicios de fuerza, cardio, yoga, pilates y barre prenatales trabajando en un grupo muscular cada día.",
    },
    {
      title: "¿Cuánto tiempo duran los ejercicios y cuántos son por semana?",
      description: `Los videos de etapa 1 duran 10-15 minutos en promedio y se practican diario. 
      Los entrenamientos de etapa 2 duran 40 min en promedio y se realizan de 2 a 5 veces por semana dependiendo de tu nivel. También hay alternativas de videos más cortos para hacer con tu bebé.`,
    },
    {
      title: "¿Los ejercicios son grabados o en vivo?",
      description: "Son entrenamientos pregrabados.",
    },
    {
      title: "¿El calendario semanal de ejercicios posparto se actualiza cada semana?",
      description: `Si se actualiza automáticamente con la sugerencia de entrenamientos para cada día de la semana.`,
    },
    {
      title: "¿Qué equipo necesito para realizar el programa de ejercicios posparto?",
      description: `Para la primera etapa del programa solo necesitas un tapete de yoga.
      Para la segunda etapa necesitarás un par de mancuernas ligeras (2-3kg), ligas de resistencia y una pelota de estabilidad.`,
    },
    {
      title: "¿Qué medida de pelota debo comprar?",
      description: `La medida de pelota depende de tu estatura. La recomendación es la siguiente:
      1.50m-1.65m    55cm
      1.66m-1.80m    65cm
      1.80m          75cm`,
    },
    {
      title: "¿Se puede adquirir desde cualquier país?",
      description: "Si. El precio es en dólares pero se hace la conversión automáticamente a tu moneda local.",
    },
    {
      title: "¿Cómo obtengo los 7 días gratis?",
      description: "Al suscribirte a cualquier plan ya sea mensual o semestral obtienes automáticamente los primeros 7 días gratis. Es necesario poner tus datos de pago, pero el cobro no se realizará hasta después del periodo de prueba. Los 7 días de prueba gratis solo se obtienen la primera vez que te inscribes al programa, en caso de un reingreso el cobro se hará en automático ese mismo dia.",
    },
    {
      title: "¿La suscripción se renueva automáticamente?",
      description: "Si. Si es suscripción mensual se renueva cada mes o si es semestral cada 6 meses mientras no canceles. Es responsabilidad de cada usuario cancelar la suscripción cuando ya no desea o no puede usarla para evitar futuros cobros. En caso de olvidar cancelar no hay reembolsos.",
    },
    {
      title: "¿Qué velocidad de internet necesito para ver el contenido?",
      description: "Para evitar tener problemas de conexión asegúrate de tener 8 MBPS en tu velocidad de internet.",
    },
  ]
}

export const getPreguntasFrecuentesStrength = async () => {
  return [
    {
      title: "¿Para quién es apto este programa?",
      description: `
      Mujeres que ya completaron por lo menos 6 meses del programa posparto y están listas para aumentar la dificultad e intensidad.
      Mujeres que no han tenido hijos y que tienen un alto nivel de fitness y desean aprender sobre su core.

      ESTE PROGRAMA NO ES APTO PARA PRINCIPIANTES NI PARA MUJERES CON DISFUNCIONES. Si tienes incontinencia, diástasis, prolapso, dolores pélvicos o de espalda, sensación de pesadez vaginal, urgencia urinaria u otro problema, el programa posparto es el ideal.
      `,
    },
    {
      title: "¿Este programa me ayudará a corregir diástasis?",
      description: "Este programa no es un programa para corregir diástasis ni otras disfunciones. Es un programa avanzado para quienes ya tienen dominio y conciencia de su core.",
    },
    {
      title: "¿En este programa seguiré teniendo acceso a los videos del core?",
      description: "Si. Seguirás teniendo todos los videos de fundamentos del core del programa posparto para que puedas seguir repasando las bases y además ejercicios avanzados de core donde podrás seguir retando la fuerza, resistencia y control de tu core.",
    },
    {
      title: "¿El programa también incluye ejercicios para trabajar otros grupos musculares además del core?",
      description: "Si. Incluye ejercicios avanzados de fuerza yoga, pilates y barre.",
    },
    {
      title: "¿Cuánto tiempo duran los entrenamientos y cuántos son por semana?",
      description: "Los entrenamientos duran 50 min en promedio de 5 a 6 veces por semana. Adicionalmente, los videos del core duran 10-15 min en promedio.",
    },
    {
      title: "¿Los entrenamientos son grabados o en vivo?",
      description: "Son entrenamientos pregrabados.",
    },
    {
      title: "¿El calendario semanal se actualiza cada semana?",
      description: `Si se actualiza automáticamente con la sugerencia de entrenamientos para cada día de la semana.`,
    },
    {
      title: "¿Qué equipo necesito?",
      description: `Un tapete de yoga, un par de mancuernas del peso que te rete lo suficiente, ligas de resistencia y una pelota de estabilidad. Adicional, puedes también complementar con un kettlebell para variar tus entrenamientos.`,
    },
    {
      title: "¿Qué medida de pelota debo comprar?",
      description: `La medida de pelota depende de tu estatura. La recomendación es la siguiente:
      1.50m-1.65m    55cm
      1.66m-1.80m    65cm
      1.80m          75cm`,
    },
    {
      title: "¿Se puede adquirir desde cualquier país?",
      description: "Si. El precio es en dólares pero se hace la conversión automáticamente a tu moneda local.",
    },
    {
      title: "¿Cómo obtengo los 7 días gratis?",
      description: "Al suscribirte a cualquier plan ya sea mensual o semestral obtienes automáticamente los primeros 7 días gratis. Es necesario poner tus datos de pago, pero el cobro no se realizará hasta después del periodo de prueba. Los 7 días de prueba gratis solo se obtienen la primera vez que te inscribes al programa, en caso de un reingreso el cobro se hará en automático ese mismo dia.",
    },
    {
      title: "¿La suscripción se renueva automáticamente?",
      description: "Si. Si es suscripción mensual se renueva cada mes o si es semestral cada 6 meses mientras no canceles. Es responsabilidad de cada usuario cancelar la suscripción cuando ya no desea o no puede usarla para evitar futuros cobros. En caso de olvidar cancelar no hay reembolsos.",
    },
    {
      title: "¿Qué velocidad de internet necesito para ver el contenido?",
      description: "Para evitar tener problemas de conexión asegúrate de tener 8 MBPS en tu velocidad de internet.",
    },
  ]
}