import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ReactComponent as EyeViewIcon } from '../../../assets/icons/eye-view-icon.svg';
import { ReactComponent as EyeHideIcon } from '../../../assets/icons/eye-hide-icon.svg';
import SEO from '../../../components/SEO';
import { ButtonCustom } from '../../../components/Button';
import TelefonoCodes from '../../../components/TelefonoCodes';
import Grid from '../components/Grid';
import Loading from '../../../components/Loading';
import { createClienta } from '../../../api/admin/clientas';
import { getProgramas } from '../../../api/admin/programas';
import { optionsToast } from '../../../config/toast';
import { useCustomState } from '../../../hooks/useCustomState';
import { isEmpty } from '../../../utils';
import parseErrorMessage from '../../../utils/parseErrorMessage';
const prefix = "/admin/clientas";

require('dayjs/locale/es');
dayjs.locale("es");

addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar'
});

const CrearClienta = () => {
  const navigate = useNavigate();
  const [state, setState] = useCustomState({
    loading: false,
    sending: false,
    userID: null,
    firstName: "",
    lastName: "",
    lastName2: "",
    phone: "",
    phone_code: "+52",
    email: "",
    password: "",
    logoutSession: false,
    isInternal: false,
    vigencia_inicio: null,
    vigencia_fin: null,
    isVisiblePassword: false,
    isVisibleCalendar: false,
    visibleCalendar: null,
    programa_seleccionado: null,
    listado_programas: [],
  });

  useEffect(() => {
    loadData();

    return () => {
      window.PrimeToast.onHide = null;
    }
    // eslint-disable-next-line
  }, [setState]);

  const message = (summary, detail) => {
    window.PrimeToast?.show({
      ...optionsToast(),
      summary: summary,
      detail: detail,
    });
  }

  const loadData = useCallback(async () => {
    setState({ loading: false });
    const programas = await getProgramas();

    setState({
      listado_programas: programas?.data,
      loading: false,
    });
    // eslint-disable-next-line
  }, []);

  const onCreate = useCallback(async () => {
    if (isEmpty(state.firstName)) {
      return message(null, 'El nombre es obligatorio.');
    }
    if (isEmpty(state.email)) {
      return message(null, 'El correo es obligatorio.');
    }
    if (isEmpty(state.password)) {
      return message(null, 'La contraseña es obligatoria.');
    }
    if (state.isInternal === true && isEmpty(state.programa_seleccionado)) {
      return message(null, 'Selecciona un programa para continuar.');
    }
    if (state.isInternal === true && isEmpty(state.vigencia_fin)) {
      return message(null, 'Selecciona la vigencia para continuar.');
    }

    try {
      setState({ sending: true });

      const response = await createClienta({
        programID: state.isInternal === true ? state.programa_seleccionado?.id : null,
        firstName: state.firstName,
        lastName: state.lastName,
        lastName2: state.lastName2,
        phone_code: state.phone_code?.dial_code,
        phone: state.phone,
        email: state.email,
        password: state.password,
        isInternal: state.isInternal,
        // dateExpirationStart: state.isInternal === true ? dayjs(state.vigencia_inicio).toISOString() : null,
        dateExpirationEnd: state.isInternal === true ? dayjs(state.vigencia_fin).toISOString() : null,
      });

      setState({ sending: false });

      if (response?.status === true) {
        window.PrimeToast.onHide = () => navigate(prefix);
        message('Clienta creada', '¡La clienta se ha creado exitosamente! Serás redirigido a la página de clientas en breve...');
      } else {
        message('Ocurrió un problema', parseErrorMessage(response) || 'Lo sentimos, ocurrió un error al crear la clienta. Por favor, inténtalo de nuevo más tarde.');
      }
    } catch (error) {
      setState({ sending: false });
      message('Ocurrió un problema', parseErrorMessage(error) || 'Lo sentimos, ocurrió un error al crear la clienta. Por favor, inténtalo de nuevo más tarde.');
    }
  }, [state, setState, navigate]);

  return (
    <>
      <SEO
        title="Crear clienta"
      />
      <Dialog
        visible={state.isVisibleCalendar}
        onHide={() => setState({ isVisibleCalendar: false })}
        style={{ width: "60%", margin: "auto" }}
        breakpoints={{ '960px': '75vw', '641px': '95vw' }}
      >
        <Calendar
          value={state.visibleCalendar === "vigencia_inicio" ? state.vigencia_inicio : state.vigencia_fin}
          onChange={(e) => setState({ [state.visibleCalendar]: e.value, isVisibleCalendar: false })}
          showOnFocus={false}
          dateFormat="M dd, yy"
          readOnlyInput
          locale="es"
          visible={true}
          inline={true}
          style={{ position: "relative", zIndex: 9999, width: "100%" }}
        />
      </Dialog>
      <Grid>
        <div className='bg-Magnolia h-full pb-4'>
          <div className='bg-white px-6 lg:px-8 py-6 shadow-sm flex items-center justify-between gap-4'>
            <h1 className='text-Crayola font-golos-medium text-3xl'>Crear clienta</h1>
          </div>
          {state.loading ? (
            <Loading containerClassName="mt-14" textClassName="text-lg" />
          ) : (
            <div className='flex flex-col bg-white mt-10 mx-6 lg:mx-8 px-8 py-10 rounded-lg'>
              <div className='flex flex-col sm:flex-row gap-4'>
                <div className='flex flex-col gap-4 flex-1'>
                  <input
                    type="text"
                    placeholder='Nombre'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.firstName}
                    onChange={(event) => setState({ firstName: event.target.value })}
                  />
                  <input
                    type="text"
                    placeholder='Apellido paterno'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.lastName}
                    onChange={(event) => setState({ lastName: event.target.value })}
                  />
                  <input
                    type="text"
                    placeholder='Apellido materno'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.lastName2}
                    onChange={(event) => setState({ lastName2: event.target.value })}
                  />
                  <TelefonoCodes
                    selectedCode={state?.phone_code}
                    onChangeCode={(event) => setState({ phone_code: event.value })}
                    value={state.phone}
                    onChange={(event) => setState({ phone: event.target.value })}
                  />
                  <input
                    type="text"
                    placeholder='Ciudad'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.city}
                    onChange={(event) => setState({ city: event.target.value })}
                  />
                  <input
                    type="number"
                    placeholder='Código postal'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    maxLength={5}
                    value={state?.zipCode}
                    onChange={(event) => setState({ zipCode: event.target.value })}
                  />
                  <input
                    type="email"
                    placeholder='Correo electrónico'
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    value={state?.email}
                    onChange={(event) => setState({ email: event.target.value })}
                  />
                  <div className='flex items-center relative'>
                    <input
                      autoComplete='false'
                      type={state.isVisiblePassword ? "text" : "password"}
                      placeholder='Contraseña'
                      className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all"
                      value={state?.password}
                      onChange={(event) => setState({ password: event.target.value })}
                    />
                    <span className='absolute right-0 p-3 c-pointer active:scale-90 transition-all select-none' onClick={() => setState({ isVisiblePassword: !state.isVisiblePassword })}>
                      {!state.isVisiblePassword ? <EyeViewIcon height={20} width={20} /> : <EyeHideIcon height={20} width={20} />}
                    </span>
                  </div>
                </div>
                <div className='flex flex-col gap-4 flex-1'>
                  <label className='inline-flex items-center gap-3 c-pointer'>
                    <input
                      checked={state.isInternal}
                      type="checkbox"
                      className="bg-Magnolia !border-0 !outline-none !shadow-none !ring-0 !ring-offset-0 p-3 !rounded-md text-Crayola transition-all"
                      onChange={(event) => setState({ isInternal: event.target.checked })}
                    />
                    <span className="flex-1 text-sm">Administrar suscripción manualmente</span>
                  </label>
                  <Dropdown
                    value={state?.programa_seleccionado}
                    onChange={(event) => setState({ programa_seleccionado: event.target.value })}
                    options={state.listado_programas}
                    optionLabel="name"
                    placeholder="Selecciona programa"
                    emptyMessage="No se encontraron resultados"
                    className="bg-Magnolia border-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 placeholder-gray-500 relative rounded-md text-sm text-gray-900 w-full transition-all"
                    disabled={!state.isInternal}
                  />
                  {/* <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                    <p className='truncate'>{(state.vigencia_inicio && dayjs(state.vigencia_inicio).format("YYYY-MM-DD")) || "Vigencia inicio"}</p>
                    {(state.isInternal) && (<button className='underline' onClick={() => setState({ isVisibleCalendar: true, visibleCalendar: "vigencia_inicio" })}>Ver calendario</button>)}
                  </div> */}
                  <div>
                    <div className='flex items-center justify-between gap-4 bg-Magnolia border-0 px-3 py-3 relative rounded-md text-sm text-gray-900 w-full transition-all'>
                      <p className='truncate'>{(state.vigencia_fin && dayjs(state.vigencia_fin).format("YYYY-MM-DD")) || "Vigencia"}</p>
                      {(state.isInternal) && (<button className='underline' onClick={() => setState({ isVisibleCalendar: true, visibleCalendar: "vigencia_fin" })}>Ver calendario</button>)}
                    </div>
                    <p className='text-muted text-gray-400 mt-1.5 pl-2'>Hasta cuando se mantiene activo</p>
                  </div>
                </div>
              </div>
              <div className='flex justify-center items-center gap-3 mt-10'>
                <ButtonCustom
                  text="CANCELAR"
                  classNameButton="border-neutral-200 bg-neutral-200 !text-neutral-600 hover:!bg-neutral-300 hover:!border-neutral-300 text-xs px-6"
                  onClick={() => navigate(prefix)}
                />
                <ButtonCustom
                  text="CREAR"
                  classNameButton="border-Crayola bg-Crayola hover:!bg-CrayolaHover hover:!border-CrayolaHover text-xs px-6"
                  onClick={onCreate}
                  loading={state.sending}
                />
              </div>
            </div>
          )}
        </div>
      </Grid>
    </>
  );
};

export default CrearClienta;