import { tinymcePlugins } from './plugins';
import { toolbar, quickbarsInsertToolbar, quickbarsSelectionToolbar } from './toolbars';
import { contextMenu } from './contextMenu';
import { menu } from './menu';
import { color_map, default_font_stack, font_family_formats, font_size_formats, line_height_formats } from './formatting';
import { filePickerCallback } from './filePickerCallback';
import { setupPlugins } from './plugins/index';

export const editorConfig = {
  min_height: 500,
  max_height: 860,
  menubar: true,
  menu: menu,
  plugins: tinymcePlugins,
  toolbar,
  setup: function (editor) {
    setupPlugins(editor);
  },
  image_title: true,
  image_caption: true,
  image_advtab: true,
  paste_data_images: true,
  automatic_uploads: false,
  file_picker_types: 'image',
  file_picker_callback: filePickerCallback,
  quickbars_insert_toolbar: quickbarsInsertToolbar,
  quickbars_selection_toolbar: quickbarsSelectionToolbar,
  contextmenu: contextMenu,
  default_font_stack,
  font_family_formats,
  font_size_formats,
  line_height_formats,
  color_map,
  font_size_input_default_unit: "px",
  body_class: "tiny-content",
  content_css: "/assets/css/editor.css",
  content_style: `@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400..700&display=swap'); body { font-family:"Golos Text",Helvetica,Arial,sans-serif; font-size:14px; }`,
};