import React, { useEffect, useMemo, useRef } from 'react';
import { ButtonPrimary } from '../../../components/Button';
import intersectionObserver from '../../../utils/intersectionObserver';

const bgImage = require('../../../assets/images/planes-back.jpg');

const Membresias = ({ typeProgram, options, onSubscribe, rulesStart = [], rulesEnd = [] }) => {
  const elementsRef = useRef([]);

  const defaultRules = useMemo(() => ([
    ...rulesStart,
    "Antes de comenzar cualquier rutina de ejercicio debes de obtener la aprobación de tu médico.",
    "Se podrán cambiar de programa",
    ...rulesEnd
  ]), [rulesStart, rulesEnd]);

  useEffect(() => {
    const observer = intersectionObserver({
      elementsRef,
      callback: (entry) => {
        if (entry.isIntersecting && entry.target.dataset.animation) {
          entry.target.classList.add(entry.target.dataset.animation);
          observer.unobserve(entry.target);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-Orchid bg-cover bg-center" style={{ backgroundImage: `url(${bgImage})`, backgroundBlendMode: "hard-light", backgroundColor: "var(--maskbg)" }}>
      <div className='relative xl:container mx-auto px-6 pt-12 pb-8'>
        {typeProgram === "fitmom-embarazo" && (
          <>
            <h2 ref={(el) => elementsRef.current.push(el)} className='animate__animated font-golos-semibold text-xl md:text-3xl lg:text-4xl text-DeepKoamaru text-center' data-animation={"animate__fadeInLeft"}>Suscríbete ahora</h2>
            <h2 ref={(el) => elementsRef.current.push(el)} className='animate__animated font-golos-semibold text-md md:text-2xl lg:text-3xl text-DeepKoamaru text-center' data-animation={"animate__fadeInLeft"}>al programa más completo de ejercicios para embarazadas</h2>
            <h2 ref={(el) => elementsRef.current.push(el)} className='mt-6 animate__animated font-golos-medium text-md md:text-3xl lg:text-2xl text-Crayola text-center' data-animation={"animate__fadeInRight"}>Prueba 7 días gratis, cancela cuando quieras</h2>
          </>
        )}
        {typeProgram === "fitmom-posparto" && (
          <>
            <h2 ref={(el) => elementsRef.current.push(el)} className='animate__animated font-golos-semibold text-xl md:text-3xl lg:text-4xl text-DeepKoamaru text-center' data-animation={"animate__fadeInLeft"}>Suscríbete ahora</h2>
            <h2 ref={(el) => elementsRef.current.push(el)} className='animate__animated font-golos-semibold text-md md:text-2xl lg:text-3xl text-DeepKoamaru text-center' data-animation={"animate__fadeInLeft"}>al programa más completo de ejercicio en el posparto</h2>
            <h2 ref={(el) => elementsRef.current.push(el)} className='mt-6 animate__animated font-golos-medium text-md md:text-3xl lg:text-2xl text-Crayola text-center' data-animation={"animate__fadeInRight"}>Prueba 7 días gratis, cancela cuando quieras</h2>
          </>
        )}
        {typeProgram === "fitmom-strength" && (
          <>
            <h2 ref={(el) => elementsRef.current.push(el)} className='animate__animated font-golos-semibold text-xl md:text-3xl lg:text-4xl text-DeepKoamaru text-center' data-animation={"animate__fadeInLeft"}>¡Suscríbete ahora!</h2>
            <h2 ref={(el) => elementsRef.current.push(el)} className='animate__animated font-golos-medium text-md md:text-3xl lg:text-2xl text-Crayola text-center' data-animation={"animate__fadeInRight"}>Obtén tu prueba gratis de 7 días, cancela cuando quieras</h2>
          </>
        )}
        <div className='flex flex-wrap gap-6 items-center justify-center mt-6 lg:mt-14'>
          <div className='mx-auto lg:mx-0'>
            <div className='relative bg-white shadow-lg shadow-DarkJungleGreen/30 rounded-xl py-8 px-7 text-center'>
              <p ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInDown"} className='animate__animated font-golos-medium text-xl md:text-3xl lg:text-2xl text-center text-DarkJungleGreen mb-0' style={{ lineHeight: "0.9rem" }}>Plan</p>
              <p ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInUp"} className='animate__animated font-golos-semibold text-xl md:text-3xl lg:text-3xl text-center text-DarkJungleGreen mb-0'>MENSUAL</p>
              <div className='border border-Orchid px-4 py-3 rounded-xl mt-4'>
                <p className='font-golos-semibold text-Orchid text-sm'>Prueba de 7 días gratis</p>
              </div>
              <p className='text-xs text-DarkJungleGreen mt-4'>al terminar periodo:</p>
              <p className='font-golos-semibold text-2xl lg:text-4xl text-Crayola'>${parseFloat(options?.price_month || 0)?.toFixed(2)}<span className='font-golos-medium text-xl lg:text-3xl'>USD</span></p>
              <p className='text-xs text-DarkJungleGreen mt-2'>El cobro se realizará mes a mes.</p>
              <div className='mt-6'>
                <ButtonPrimary
                  text={"¡Suscríbete!"}
                  withIcon={true}
                  classNameContainer="flex justify-center"
                  onClick={() => onSubscribe("mensual")}
                />
              </div>
            </div>
          </div>
          <div className='mx-auto lg:mx-0'>
            <div className='bg-white shadow-lg shadow-DarkJungleGreen/30 rounded-xl pb-8 text-center'>
              <div className='bg-Orchid py-2 px-4 mb-6 rounded-t-xl'>
                <p className='font-golos-semibold text-white text-lg'>AHORRA 50%</p>
              </div>
              <div className='px-7'>
                <p ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInDown"} className='animate__animated font-golos-medium text-xl md:text-3xl lg:text-2xl text-center text-DarkJungleGreen mb-0' style={{ lineHeight: "0.9rem" }}>Plan</p>
                <p ref={(el) => elementsRef.current.push(el)} data-animation={"animate__fadeInUp"} className='animate__animated font-golos-semibold text-xl md:text-3xl lg:text-3xl text-center text-DarkJungleGreen mb-0'>SEMESTRAL</p>
                <div className='border border-Orchid px-4 py-3 rounded-xl mt-4'>
                  <p className='font-golos-semibold text-Orchid text-sm'>Prueba de 7 días gratis</p>
                </div>
                <p className='text-xs text-DarkJungleGreen mt-4'>al terminar periodo:</p>
                <p className='font-golos-semibold text-xl lg:text-3xl text-neutral-300 line-through mt-3'>
                  ${(parseFloat(options?.price_month || 0) * 6)?.toFixed(2)}
                  <span className='inline-block font-golos-medium text-lg lg:text-2xl line-through'>USD</span>
                </p>
                <p className='font-golos-semibold text-2xl lg:text-4xl text-Crayola'>${parseFloat(options?.price_biannual || 0)?.toFixed(2)}<span className='font-golos-medium text-xl lg:text-3xl'>USD</span></p>
                <p className='text-xs text-DarkJungleGreen mt-2'>El cobro se realizará cada 6 meses.</p>
                <div className='mt-6'>
                  <ButtonPrimary
                    text={"¡Suscríbete!"}
                    withIcon={true}
                    classNameContainer="flex justify-center"
                    onClick={() => onSubscribe("semestral")}
                  />
                </div>
                <div className='mx-auto max-w-[240px]'>
                  <p className='font-golos-semibold text-lg text-DarkJungleGreen mt-6 underline'>Obtén un 30% adicional usando el código: BUENFIN</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-8'>
          {defaultRules.map((item, index) => (
            <p key={`rule-key-${index}`} className='font-golos text-DarkJungleGreen text-sm text-center mt-2'>
              <span className='text-red-600'>*</span> {item}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Membresias;